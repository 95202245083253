'use client';

import { Nullable } from '@/type-utils';
import { observer } from 'mobx-react-lite';
import { InputHTMLAttributes, ReactElement, ReactNode } from 'react';

import { classes } from '@/next-utils/css-utils/scss-utils';
import S from './styles.module.scss';

export interface ICheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  /** The caption of the checkbox. */
  label?: string | JSX.Element;
  /** The error string to be displayed. */
  error?: Nullable<ReactNode>;
}

/**
 * Checkboxes allow the user to select one or more items from a set.
 */
export const CheckboxCore = observer(
  ({ id, name, label, error, ...rest }: ICheckboxProps): ReactElement => {
    return (
      <div className={classes(S.container, error ? S.error : '')}>
        <div className={S.inputContainer}>
          <input id={id} name={name} {...rest} type="checkbox" />
          {label && <label htmlFor={id}>{label}</label>}
        </div>
        {error && <div className={S.errorMessage}>{error}</div>}
      </div>
    );
  }
);
