'use client';

import { useRouter } from '@/react/utils/router-utils/useRouter';
import { useEffect } from 'react';
import { IForm } from '../view-models/Form';

/**
 * Hook that clears the given forms when the user navigates to a different page.
 *
 * NOTE: do **NOT** call this hook with dynamic arguments (i.e. spread syntax).
 * Instead, pass every form explicitly. This is to minimize the chances of forms
 * being passed in a different order between renders, or a different amount of
 * forms being passed between renders; both of which would result in the hook
 * re-executing needlessly.
 *
 * @param forms - The form models to clear.
 * @example
 * useClearFormsOnNavigation(form1, form2, form3); // ✅
 * useClearFormsOnNavigation(...forms); // ❌
 */
export const useClearFormsOnNavigation = <T extends IForm>(
  ...forms: Array<T>
): void => {
  const router = useRouter();

  useEffect(
    function clearFormsOnNavigation() {
      const handler = (): void => forms.forEach((form) => form.clear());

      router.events.on('routeChangeComplete', handler);

      return () => {
        router.events.off('routeChangeComplete', handler);
      };
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps
        -- While we cannot statically verify that we've passed the
        correct dependencies, it's unlikely that the number of forms
        will change during the lifecycle of the component. */
    [router, ...forms]
  );
};
