'use client';

import { useRouter } from '@/react/utils/router-utils/useRouter';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';

import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import { msg } from '@/services/isomorphic/I18NService';
import LoggerService from '@/services/isomorphic/LoggerService';
import UserService from '@/services/isomorphic/UserService';

import { useClearFormsOnNavigation } from '@/react/hooks/useClearFormsOnNavigation';
import { useGlobalContext } from '@/react/hooks/useGlobalContext';
import { LoginFormModel } from '@/react/view-models/forms/Login';
import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';
import UserInteractionService, {
  EventType
} from '@/services/isomorphic/UserInteractionService';
import { InvalidCredentialsError } from '@/services/isomorphic/integrations/CognitoService';
import { Alert } from '../../core-ui/Alert';
import { Form } from '../../core-ui/Form';

import { ForgetPasswordModal } from '../ForgetPasswordModal';
import { LoginFormFields } from './LoginFormFields';
import { login_wrongCredentials } from "@/lang/__generated__/ahnu/login_wrongCredentials";
import { login_unknownError } from "@/lang/__generated__/ahnu/login_unknownError";

/**
 * A login form for the user to sign in to their account.
 */
export const LoginForm: FunctionComponent = () => {
  const router = useRouter();
  const { cart } = useGlobalContext();

  const [errorAlertContent, setErrorAlertContent] = useState<string>('');
  const [openForgotPasswordModal, setOpenForgotPasswordModal] = useState(false);
  const [isCheckoutFlow, setIsCheckoutFlow] = useState(false);

  const [form] = useState(
    () =>
      new LoginFormModel({
        email: '',
        password: '',
        rememberMe: false
      })
  );

  useClearFormsOnNavigation(form);

  const config = ConfigurationService.getConfig('user');
  const location = config.getSetting('loginRedirectLocation').value;
  const checkoutLocation = config.getSetting('checkoutRedirectLocation').value;

  // Check to see if the user is logging in via the checkout flow.
  useEffect(() => {
    const { url } = EnvironmentService;
    const isCheckout = url.pathname.includes(checkoutLocation);
    if (isCheckout) {
      setIsCheckoutFlow(true);
    }
  }, [checkoutLocation]);

  const handleSubmit = async (): Promise<void> => {
    setErrorAlertContent('');

    // This only runs if form is already deemed valid.
    const credentials = {
      email: form.email,
      password: form.password
    };

    try {
      const user = await UserService.logIn(credentials);
      await cart.tryMergeWithUserCart(user);

      // If logging in during checkout flow, update router to direct user to '/checkout'.
      if (isCheckoutFlow) {
        router.push(checkoutLocation);
        return;
      }

      // Add event to GTM
      UserInteractionService.makeAction({ action: EventType.LoginSuccess });

      router.push(location);
    } catch (error) {
      // Add event to GTM
      UserInteractionService.makeAction({ action: EventType.LoginError });

      if (error instanceof InvalidCredentialsError) {
        setErrorAlertContent(msg(login_wrongCredentials));
      } else {
        setErrorAlertContent(msg(login_unknownError));
      }

      LoggerService.error(error as Error);
    }
  };

  const handleCloseForgotPasswordModal = useCallback(
    function handleCloseForgotPasswordModal() {
      setOpenForgotPasswordModal(false);
    },
    []
  );

  const handleOpenForgotPasswordModal = useCallback(
    function handleOpenForgotPasswordModal() {
      setOpenForgotPasswordModal(true);
    },
    []
  );

  return (
    <>
      <Form
        submit={handleSubmit}
        form={form}
        submitInteractionDetails={{
          action: EventType.LoginSubmit
        }}
        enterInteractionDetails={{
          action: EventType.FormInput,
          formName: 'login'
        }}
      >
        <Alert variant="error" visible={errorAlertContent !== ''}>
          {errorAlertContent}
        </Alert>

        <LoginFormFields
          onForgotPassword={handleOpenForgotPasswordModal}
          isCheckoutFlow={isCheckoutFlow}
        />
      </Form>

      <ForgetPasswordModal
        open={openForgotPasswordModal}
        handleModalClose={handleCloseForgotPasswordModal}
      />
    </>
  );
};

export default LoginForm;
