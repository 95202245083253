'use client';

import { ForgetPasswordFormModel } from '@/react/view-models/forms/user/ForgetPassword';
import { msg } from '@/services/isomorphic/I18NService';
import { FunctionComponent } from 'react';
import { useFormContext } from '../../../../hooks/useFormContext';
import { Button } from '../../../core-ui/Button';
import { TextField } from '../../../core-ui/TextField';

import S from './styles.module.scss';
import { forms_field_email } from "@/lang/__generated__/ahnu/forms_field_email";
import { login_resetPassword } from "@/lang/__generated__/ahnu/login_resetPassword";

/**
 * The field for the forget password modal.
 */
export const ForgetPasswordFields: FunctionComponent = () => {
  const { form, isLoading } = useFormContext(ForgetPasswordFormModel);

  return (
    <>
      <TextField
        className={S.input}
        label={msg(forms_field_email)}
        fullWidth
        type="email"
        fieldName="email"
        datatestID="mdlPasswordEmail"
        formModel={form}
      />
      <Button
        fullWidth
        submit
        className={S.submitButton}
        disabled={isLoading}
        loading={isLoading}
        datatestID="mdlPasswordSubmit"
      >
        {msg(login_resetPassword)}
      </Button>
    </>
  );
};
