import {
  nonEmpty,
  validEmail
} from '@/react/view-models/Form/validation-utils';
import { msg } from '@/services/isomorphic/I18NService';
import { DTO } from '@/type-utils';
import { toStatelessImmutable } from '@/utils/object-utils';
import { makeObservable, observable } from 'mobx';
import { FormModel, validatable } from '../../Form';
import { forms_field_email } from "@/lang/__generated__/ahnu/forms_field_email";
import { forms_field_password } from "@/lang/__generated__/ahnu/forms_field_password";
import { forms_field_rememberMe } from "@/lang/__generated__/ahnu/forms_field_rememberMe";

/**
 * Describes the fields of the Login form. {@see {@link LoginForm}}.
 */
export interface ILoginForm {
  /**
   * User's email.
   */
  email: string;
  /**
   * User's password.
   */
  password: string;
  /**
   * Checkbox that can be toggled if we want the session to persist.
   */
  rememberMe: boolean;
}

/** @inheritdoc */
export class LoginFormModel
  extends FormModel<ILoginForm>
  implements ILoginForm
{
  /** @inheritdoc */
  @observable
  @validatable({
    get name() {
      return msg(forms_field_email);
    },
    validations: [validEmail]
  })
  public email: string;

  /** @inheritdoc */
  @observable
  @validatable({
    get name() {
      return msg(forms_field_password);
    },
    validations: [nonEmpty]
  })
  public password: string;

  /** @inheritdoc */
  @observable
  @validatable({
    get name() {
      return msg(forms_field_rememberMe);
    },
    validations: []
  })
  public rememberMe: boolean;

  /** @inheritdoc */
  public constructor(dto: DTO<ILoginForm>) {
    super(dto);
    this.email = dto.email;
    this.password = dto.password;
    this.rememberMe = dto.rememberMe;

    makeObservable(this);
  }

  /** @inheritDoc */
  public toDTO(): DTO<ILoginForm> {
    const { email, password, rememberMe } = this;

    return toStatelessImmutable<Partial<DTO<ILoginForm>>>({
      email,
      password,
      rememberMe
    }) as DTO<ILoginForm>;
  }
}
