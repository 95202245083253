'use client';

/* eslint-disable jsx-a11y/no-static-element-interactions -- See below. */
// The tooltip elements (the one with the class `S.tooltip`) have a key down
// listener to close the tooltip when the user presses the Escape key.
// Div elements, however, are considered static; and this is why we get this
// error.
//
// Now, the reason the key listener is placed on the tooltip is to catch key
// events generated on its descendants and not on itself.
// This is actually an edge case described here:
// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/2b133ecc0ba1b882ca06f0813b1afcdf64195757/docs/rules/no-static-element-interactions.md#case-the-event-handler-is-only-being-used-to-capture-bubbled-events
//
// Since there is no suitable role for elements that are meant to handle
// bubbled events, we can justify disabling this rule.

/* eslint-disable react/no-array-index-key -- See below */
// There's nothing else to key buttons and links with.

import {
  FunctionComponent,
  MutableRefObject,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { classes } from '@/next-utils/css-utils/scss-utils';
import { WithChildren } from '@/type-utils/component';
import { AnyAcceptableCSSLength } from '@/type-utils/css';

import { msg } from '@/services/isomorphic/I18NService';
import { useOnClickedOutside } from '../../../hooks/useOnClickOutside';
import IStylable from '../../traits/IStylable';
import { HoverIntent } from '../../utility/HoverIntent';
import { Button, IButtonProps } from '../Button';
import { IIconProps, Icon, IconTypes } from '../Icon';
import { ILinkProps, Link } from '../Link';

import S from './styles.module.scss';
import { general_closeTooltip } from "@/lang/__generated__/ahnu/general_closeTooltip";

export interface ITooltipProps extends PropsWithChildren, IStylable {
  /** Text to show as the tooltip title. */
  title?: string;

  /** Content to show on the tooltip body. May be either plain text or any JSX element. */
  body?: string | JSX.Element;

  /**
   * If the tooltip text should wrap or not. Defaults to `false`.
   *
   * This prop is recommended when using tooltips with short text contents
   * wrapping small components such as buttons. This is because by default, the tooltip
   * will take the width of its parent.
   *
   * As an alternative, you can always set a custom size with SCSS.
   */
  wrap?: boolean;

  /**
   * The position this tooltip will appear relative to its wrapped content.
   * Defaults to `up`.
   */
  position?: 'up' | 'down' | 'left' | 'right';

  /** Tooltip style variant. Defaults to `light`. */
  variant?: 'light' | 'dark';

  /** An icon to add to the tooltip. */
  icon?: IconTypes | 'none';

  /** Props to pass to the internal {@link Icon} component. */
  iconProps?: IIconProps;

  /**
   * Whether this tooltip should show when the wrapped contents are hovered.
   * Defaults to `true`.
   */
  showOnHover?: boolean;

  /**
   * Use this to manually control the tooltip's visibility from outside.
   *
   * It is advised to provide change callbacks (`onChange` or `onOpen` + `onClose`) to listen
   * for changes and update the external state when using this prop.
   */
  visible?: boolean;

  /**
   * Callback that will be called when the tooltip is either opened or closed.
   * @param active - The current state of the tooltip.
   */
  onChange?: (active: boolean) => void;

  /** Callback that will be called when the tooltip is opened. */
  onOpen?: () => void;

  /** Callback that will be called when the tooltip is closed. */
  onClose?: () => void;

  /**
   * If enabled, the tooltip will have a small "X" button to close it.
   * Meant to be used with the `visible` prop.
   *
   * Using this prop will force `interactive` to `true`.
   */
  closable?: boolean;

  /**
   * If true, the tooltip will be focusable and clickable. Use this prop when including links
   * or other interactive elements on the tooltip body.
   *
   * Defaults to `false`; but will be forced to `true` if either the `closable`, `links` or
   * `buttons` props are present.
   *
   * **Accessibility Note:**
   * Technically speaking, there is no such thing as an "interactive tooltip".
   * Instead, when this component is deemed as interactive, it becomes a non-modal
   * dialog instead of a tooltip.
   *
   * @see [ARIA tooltip role](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/tooltip_role) vs [ARIA dialog role](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/dialog_role).
   */
  interactive?: boolean;

  /**
   * Links to show in the lower left of the tooltip.
   *
   * Using this prop will force `interactive` to `true`.
   */
  links?: Array<WithChildren<ILinkProps>>;

  /**
   * Buttons to show in the lower right of the tooltip.
   *
   * Using this prop will force `interactive` to `true`.
   */
  buttons?: Array<WithChildren<IButtonProps>>;

  /**
   * If true, it toggles open and close on click event. Clicking off the open tooltip
   * closes the tooltip.
   */
  toggleOnClick?: boolean;

  /**
   * If provided, the tooltip content will be translated by the specified amount;
   * on the X axis when position is either Up or Down, and on the Y axis if position
   * is either Left or Right.
   *
   * Use this to better accommodate the tooltip if necessary. Like for instance, when it
   * doesn't fit within the viewport due to its positioning.
   */
  translateContent?: AnyAcceptableCSSLength;

  /** Allows us to specify the width of the tooltip body component. */
  bodyWidth?: AnyAcceptableCSSLength;
}

/**
 * Provides an easy way to display customizable tooltips: non-modal
 * overlays that contain supplemental information for a user interface.
 *
 * **Accessibility Note:**
 * It is strongly advised to give this tooltip an `id` prop and pass it as the
 * [aria-describedby](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-describedby)
 * prop on components that it describes.
 *
 * @example
 *
 * ```jsx
 * <Tooltip body="Like this post" variant="dark">
 *    <Icon icon={IconTypes.Heart} />
 * </Tooltip>
 * ```
 */
export const Tooltip: FunctionComponent<ITooltipProps> = ({
  title,
  body,
  wrap = false,
  position = 'up',
  variant = 'light',
  icon,
  iconProps,
  showOnHover = true,
  visible,
  onChange,
  onOpen,
  onClose,
  closable,
  interactive,
  links,
  buttons,
  toggleOnClick,
  className,
  style,
  id,
  children,
  translateContent,
  bodyWidth
}) => {
  const [active, setActive] = useState<boolean>(visible ?? false);

  const isInteractive = !!(interactive ?? links ?? buttons);

  useEffect(() => {
    // Whenever active changes, call the respective callbacks.
    if (active) {
      if (onOpen) onOpen();
      if (onChange) onChange(true);
    } else {
      if (onClose) onClose();
      if (onChange) onChange(false);
    }
  }, [active]);

  // This value will be the translation transform to pass to the tooltip content
  // when `translateContent` is provided.
  const translationTransform = useMemo<string | null>(() => {
    if (translateContent) {
      switch (position) {
        case 'up':
        case 'down':
          // Translate in the X axis
          return `translateX(${translateContent})`;

        case 'left':
        case 'right':
          // Translate in the Y axis
          return `translateY(${translateContent})`;

        default:
          return null;
      }
    }
    return null;
  }, [translateContent, position]);

  /** This allows you to click outside the element to close the tooltip. */
  const wrapperRef = useRef<HTMLDivElement>(null);
  useOnClickedOutside(wrapperRef as MutableRefObject<HTMLDivElement>, (e) => {
    if (toggleOnClick && active) {
      setActive(false);
    }
  });

  return (
    <div className={S.wrapper} ref={wrapperRef}>
      <HoverIntent
        // We only want the tooltip to be focusable if it is interactive.
        activatedByFocus={isInteractive}
        disabled={!showOnHover}
        onHoverChange={setActive}
        delay={0}
      >
        <div
          className={S.container}
          // Kill two birds with one stone by using this placement container
          // as the ARIA live region.
          role="region"
          aria-live="polite"
        >
          <div
            id={id}
            style={style}
            className={classes(className, S.tooltip, S[position], {
              [S.visible]: active,
              [S.interactive]: isInteractive
            })}
            // Hide the tooltip for screen readers when inactive.
            aria-hidden={!active}
            // If both a title and an id are present, make an id for the title and
            // indicate that it labels the tooltip.
            aria-labelledby={title && id ? `${id}_title` : undefined}
            // Use the `dialog` role when interactive. According to WAI-ARIA guidelines,
            // "a tooltip can not contain interactive elements like links, inputs, or buttons.".
            // Hence, an "interactive tooltip" is actually a non-modal dialog.
            role={isInteractive ? 'dialog' : 'tooltip'}
            // See top of file for additional details on this.
            onKeyDown={(e) => {
              // Close this tooltip on Escape
              if (e.key === 'Escape') {
                setActive(false);
              }
            }}
            // This uses the `inert` HTML attribute to disable all interactive
            // elements within the tooltip.
            //
            // See
            // https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/inert
            //
            // Q: Why use a ref instead of just passing `inert` as a prop?
            //
            // A: React doesn't yet accept `inert` as a standard property it
            // seems. So it refuses to set `inert` when passed as a prop. To go
            // around this, we can directly set it on the node.
            //
            // See: https://github.com/WICG/inert/issues/58
            //
            // Q: If `inert` is considered non-standard by React, does it mean
            // that it isn't widely supported yet?
            //
            // A: That used to be the case until recently. CanIUse now reports
            // a global support of 94.66%.
            //
            // See https://caniuse.com/mdn-api_htmlelement_inert
            // 
            // Support for `inert` was introduced to React 19. Remove this
            // workaround after updating.
            //
            // See: https://github.com/facebook/react/issues/17157
            ref={(node) =>
              node &&
              // If this tooltip is interactive...
              (isInteractive
                ? // Remove the inert attribute.
                  node.removeAttribute('inert')
                : // Else, include it.
                  node.setAttribute('inert', ''))
            }
          >
            {/* Tooltip Triangle */}
            <div
              className={classes(S.triangle, {
                [S.visible]: active
              })}
            />

            <div
              className={classes(S.mainContainer, S[variant], {
                [S.nowrap]: !wrap,
                [S.visible]: active
              })}
              style={{
                ...(translationTransform && {
                  transform: translationTransform
                }),
                ...(bodyWidth && { maxWidth: `min(90vw, ${bodyWidth})` })
              }}
            >
              {closable ? (
                <Button
                  variant="text"
                  className={S.closeButton}
                  onClick={() => setActive(false)}
                  ariaLabel={msg(general_closeTooltip)}
                >
                  <Icon icon={IconTypes.Close} />
                </Button>
              ) : null}

              {icon && icon !== 'none' ? (
                <div className={S.iconContainer}>
                  <Icon icon={icon} className={S.icon} {...iconProps} />
                </div>
              ) : null}

              <div className={classes(S.mainContent, { [S.nowrap]: !wrap })}>
                {title ? (
                  <div id={id ? `${id}_title` : undefined} className={S.title}>
                    {title}
                  </div>
                ) : null}

                {body ? <div className={S.body}>{body}</div> : null}

                {buttons || links ? (
                  <div className={S.footer}>
                    {buttons ? (
                      <div className={S.buttons}>
                        {buttons.map((b, idx) => {
                          return <Button key={`button_${idx}`} {...b} />;
                        })}
                      </div>
                    ) : null}

                    {links ? (
                      <div className={S.links}>
                        {links.map((l, idx) => {
                          return <Link key={`link_${idx}`} {...l} />;
                        })}
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {toggleOnClick ? (
          <Button variant="text" onClick={() => setActive(!active)}>
            {children}
          </Button>
        ) : (
          children
        )}
      </HoverIntent>
    </div>
  );
};
