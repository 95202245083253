'use client';

import { observer } from 'mobx-react-lite';
import { InputHTMLAttributes, ReactNode } from 'react';

import { IForm } from '@/react/view-models/Form';
import { Nullable } from '@/type-utils';
import { useViewModel } from '../../../hooks/useViewModel';

import ITestIdentifiable from '../../traits/ITestIdentifiable';
import { CheckboxCore } from '../CheckboxCore';

export interface ICheckboxProps<T extends IForm>
  extends InputHTMLAttributes<HTMLInputElement>,
    ITestIdentifiable {
  /** The caption of the checkbox. */
  label?: string | JSX.Element;

  /**
   * This is a mirror for the value attribute. Inside an input mask, you cannot pass a value attribute
   * into the child component.
   */
  formModel: T;

  /** The name of the field being used. */
  fieldName: keyof T;
}

/**
 * Checkboxes allow the user to select one or more items from a set.
 */
export const Checkbox = observer(function Checkbox<T extends IForm>({
  id,
  name,
  label,
  fieldName,
  formModel,
  datatestID,
  ...rest
}: ICheckboxProps<T>) {
  const [vm] = useViewModel({
    /**
     * Set the radio field to touched so that it will be validated.
     */
    setTouch() {
      formModel.setTouch(fieldName);
    },

    /**
     * The current error of this radio form element.
     * @returns A react node to be rendered.
     */
    get error(): Nullable<ReactNode> {
      const { errors } = formModel.validateProperty(fieldName);

      // When the `key` argument is present for `validate()`
      // there can only be 1 invalid property in the array.
      // If the array is empty, there are no errors.
      const displayError = errors[0] ?? null;

      // Render a fragment containing all the errors.
      return displayError ? <>{displayError}</> : null;
    },

    /**
     * Is the form currently valid, if not using a form model, will always be true.
     * @returns Is it valid.
     */
    get isValid(): boolean {
      const { isValid } = formModel.validateProperty(fieldName);
      return isValid;
    },

    /**
     * Gets the current value of checkbox from the form model.
     * @returns A boolean or nothing.
     */
    get value(): boolean {
      return (formModel as Record<string, unknown>)[
        fieldName as string
      ] as boolean;
    },

    /**
     * Gets the checked value considering the passed in checked override.
     * @returns A boolean representing if it is checked or not.
     */
    get checked(): boolean {
      return this.value;
    }
  });

  return (
    <CheckboxCore
      id={id}
      name={fieldName as string}
      onChange={(e) => {
        vm.setTouch();
        (formModel as Record<string, unknown>)[fieldName as string] =
          e.target.checked;
      }}
      type="checkbox"
      checked={vm.checked}
      label={label}
      error={vm.error}
      data-testid={datatestID}
      {...rest}
    />
  );
});
