'use client';

import { LoginFormModel } from '@/react/view-models/forms/Login';
import { msg } from '@/services/isomorphic/I18NService';
import { EventType } from '@/services/isomorphic/UserInteractionService';
import { observer } from 'mobx-react-lite';
import { Button } from '../../../core-ui/Button';
import { Checkbox } from '../../../core-ui/Checkbox';
import { Icon, IconTypes } from '../../../core-ui/Icon';
import { Spinner } from '../../../core-ui/Spinner';
import { TextField } from '../../../core-ui/TextField';

import { useFormContext } from '../../../../hooks/useFormContext';

import S from './styles.module.scss';
import { login_submit } from "@/lang/__generated__/ahnu/login_submit";
import { login_loginCheckout } from "@/lang/__generated__/ahnu/login_loginCheckout";
import { forms_field_email } from "@/lang/__generated__/ahnu/forms_field_email";
import { forms_field_password } from "@/lang/__generated__/ahnu/forms_field_password";
import { forms_field_rememberMe } from "@/lang/__generated__/ahnu/forms_field_rememberMe";
import { login_forgotPassword } from "@/lang/__generated__/ahnu/login_forgotPassword";

interface ILoginFormFieldsProps {
  /**
   * Callback for when the user clicks the forgot password button.
   */
  onForgotPassword: () => void;

  /**
   * Check to see if the user is logging in via the checkout flow.
   * If true, the submit button on 'checkout/login' page will display
   * a different login text - ex. 'Login & Checkout'.
   */
  isCheckoutFlow?: boolean;
}

/**
 * A form the user can fill up to login.
 * @throws If the form context doesn't wrap this component or the
 * `FormModel` is of the wrong type.
 */
export const LoginFormFields = observer(function LoginFormFields({
  onForgotPassword,
  isCheckoutFlow
}: ILoginFormFieldsProps) {
  const { form, isLoading } = useFormContext(LoginFormModel);

  const submitMessage = msg(login_submit);
  const checkoutMessage = msg(login_loginCheckout);

  return (
    <>
      <TextField
        className={S.input}
        label={msg(forms_field_email)}
        fullWidth
        fieldName="email"
        datatestID="lgnEmail"
        type="email"
        formModel={form}
        enterInteractionDetails={{
          action: EventType.FormInput,
          formName: 'login',
          formFieldName: 'email'
        }}
      />
      <TextField
        className={S.input}
        label={msg(forms_field_password)}
        fullWidth
        fieldName="password"
        datatestID="lgnPassword"
        type="password"
        showablePassword
        formModel={form}
        enterInteractionDetails={{
          action: EventType.FormInput,
          formName: 'login',
          formFieldName: 'password'
        }}
      />
      <div className={S.bottomItems}>
        <div className={S.innerBottomItems}>
          <Checkbox
            id="rememberMe"
            fieldName="rememberMe"
            datatestID="lgnRememberMe"
            formModel={form}
            label={msg(forms_field_rememberMe)}
          />
        </div>
        <Button
          className={S.passwordLink}
          variant="text"
          datatestID="lgnForgotPassword"
          onClick={onForgotPassword}
        >
          {msg(login_forgotPassword)}
        </Button>
      </div>
      <Button
        fullWidth
        className={S.submitButton}
        disabled={isLoading}
        datatestID="lgnSubmit"
        submit
      >
        {isLoading ? (
          <Spinner className={S.spinner} />
        ) : (
          <>
            <Icon icon={IconTypes.LockKeyHole} />
            {isCheckoutFlow ? checkoutMessage : submitMessage}
          </>
        )}
      </Button>
    </>
  );
});
