'use client';

import { FunctionComponent, useCallback, useState } from 'react';

import { ForgetPasswordFormModel } from '@/react/view-models/forms/user/ForgetPassword';

import AccountService from '@/services/isomorphic/AccountService';
import { msg } from '@/services/isomorphic/I18NService';
import LoggerService from '@/services/isomorphic/LoggerService';
import { Alert } from '../../core-ui/Alert';
import { Button } from '../../core-ui/Button';
import { Form } from '../../core-ui/Form';
import { Icon, IconTypes } from '../../core-ui/Icon';
import { Modal } from '../../core-ui/Modal';
import { ModalHeader } from '../../core-ui/Modal/ModalHeader';
import { ForgetPasswordFields } from './ForgetPasswordFields';

import S from './styles.module.scss';
import { account_resetPassword_unknownError } from "@/lang/__generated__/ahnu/account_resetPassword_unknownError";
import { login_resetPassword } from "@/lang/__generated__/ahnu/login_resetPassword";
import { login_resetPasswordPrompt } from "@/lang/__generated__/ahnu/login_resetPasswordPrompt";
import { login_forgotPasswordEmailSuccess } from "@/lang/__generated__/ahnu/login_forgotPasswordEmailSuccess";
import { login_submit } from "@/lang/__generated__/ahnu/login_submit";

export interface IPasswordModalProps {
  /** Whether the component is currently open. */
  open: boolean;
  /** Called when the close button is clicked. */
  handleModalClose: () => void;
}

/**
 * A modal where the user can input their e-mail and start the "forget my password" process.
 */
export const ForgetPasswordModal: FunctionComponent<IPasswordModalProps> = ({
  open,
  handleModalClose
}) => {
  const [error, setError] = useState('');
  const [form] = useState(
    new ForgetPasswordFormModel({
      email: ''
    })
  );

  const [showSuccessMsg, setShowSuccessMsg] = useState(false);

  const handleSubmit = useCallback(async () => {
    setShowSuccessMsg(false);
    try {
      setError('');
      await AccountService.forgotPassword(form.email);

      setShowSuccessMsg(true);
      form.clear();
    } catch (error) {
      LoggerService.error(error as Error);
      setError(msg(account_resetPassword_unknownError));
    }
  }, [form]);

  return (
    <Modal isOpen={open} onClose={handleModalClose} className={S.modalWrapper}>
      <ModalHeader onClose={handleModalClose} className={S.modalHeader}>
        {msg(login_resetPassword)}
      </ModalHeader>
      <div className={S.modalBody}>
        <Alert variant="error" visible={Boolean(error)}>
          {error}
        </Alert>
        {!showSuccessMsg ? (
          <Form form={form} submit={handleSubmit}>
            <div className={S.modalDescription}>
              {msg(login_resetPasswordPrompt)}
            </div>
            <ForgetPasswordFields />
          </Form>
        ) : (
          <>
            <div className={S.modalDescription}>
              {msg(login_forgotPasswordEmailSuccess)}
            </div>
            <Button
              fullWidth
              className={S.submitButton}
              datatestID="lgnSubmit"
              submit
              onClick={() => window.location.reload()}
            >
              <>
                <Icon icon={IconTypes.LockKeyHole} />
                {msg(login_submit)}
              </>
            </Button>
          </>
        )}
      </div>
    </Modal>
  );
};
