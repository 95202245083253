import {
  nonEmpty,
  validEmail
} from '@/react/view-models/Form/validation-utils';
import { msg } from '@/services/isomorphic/I18NService';
import { DTO } from '@/type-utils';
import { toStatelessImmutable } from '@/utils/object-utils';
import { makeObservable, observable } from 'mobx';
import { FormModel, validatable } from '../../../Form';
import { forms_field_email } from "@/lang/__generated__/ahnu/forms_field_email";

/**
 * Describes the fields of the ForgetPassword form. {@see {@link ForgetPasswordModal}}.
 */
export interface IForgetPassword {
  /**
   * User's email address.
   */
  email: string;
}

/** @inheritdoc */
export class ForgetPasswordFormModel
  extends FormModel<IForgetPassword>
  implements IForgetPassword
{
  /** @inheritdoc */
  @observable
  @validatable({
    get name() {
      return msg(forms_field_email);
    },
    validations: [validEmail, nonEmpty]
  })
  public email: string;

  /** @inheritdoc */
  public constructor(dto: DTO<IForgetPassword>) {
    super(dto);
    this.email = dto.email;

    makeObservable(this);
  }

  /** @inheritDoc */
  public toDTO(): DTO<IForgetPassword> {
    const { email } = this;

    return toStatelessImmutable<Partial<DTO<IForgetPassword>>>({
      email
    }) as DTO<IForgetPassword>;
  }
}
