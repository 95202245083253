'use client';

import { classes } from '@/next-utils/css-utils/scss-utils';
import { observer } from 'mobx-react-lite';
import {
  InputHTMLAttributes,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useId,
  useRef,
  useState
} from 'react';

import { Icon, IconTypes } from '@/react/components/core-ui/Icon';
import { ITooltipProps, Tooltip } from '@/react/components/core-ui/Tooltip';
import ITestIdentifiable from '@/react/components/traits/ITestIdentifiable';
import { useViewModel } from '@/react/hooks/useViewModel';
import { FormModel, IForm, InputValue } from '@/react/view-models/Form';
import { msg } from '@/services/isomorphic/I18NService';

import UserInteractionService, {
  InteractionDetails
} from '@/services/isomorphic/UserInteractionService';

import { Nullable } from '@/type-utils';
import S from './styles.module.scss';
import { forms_requiredIndicator } from "@/lang/__generated__/ahnu/forms_requiredIndicator";

export interface ITextFieldProps<T extends IForm>
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'value>'>,
    ITestIdentifiable {
  /** Allows the text field to full fill the width of the parent component. */
  fullWidth?: boolean;

  /** Represents a caption for the text field. */
  label: string;

  /**
   * The `error` prop toggles the error state. The `helperText` prop can then
   * be used to provide feedback to the user about the error.
   */
  error?: boolean;

  /**
   * The `tooltip` is used to add a QuestionCircle icon on the right side of the label. When it gets hovered, this text
   * appears in a floating tooltip when the cursor is.
   */
  tooltip?: string | ReactElement;

  /**
   * Props for the `ToolTip` that will be spread over the component.
   */
  tooltipArguments?: ITooltipProps;

  /**
   * The `helperText` is used to give context about a field's input, such as
   * how the input will be used.
   */
  helperText?: Nullable<string>;

  /**
   * This is a mirror for the value attribute. Inside an input mask, you cannot pass a value attribute
   * into the child component.
   */
  formModel: T;

  /** The name of the field being used. */
  fieldName: Extract<keyof T, string>;

  /** Is this contained within an input mask.  */
  isInInputMask?: boolean;

  /**
   * An eye icon will appear on the right that you can toggle back and forth
   * between showing or not the password.
   * Before using this, check if the type is `password`, since it's the only way it will work.
   */
  showablePassword?: boolean;

  /**
   * If isOptional is true then no asterisk will be added to the label.
   */
  isOptional?: boolean;

  /**
   * Allows you to make a user interaction event when the text field is clicked.
   * This will allow the use of the {@link UserInteractionService} to make analytics events in GTM, Coveo, etc.
   */
  enterInteractionDetails?: InteractionDetails;
}

/**
 * The interface of the {@link useViewModel view model} for the text field.
 * Models the current value, flag indicating if input is in error state,
 * and the react node(s) contianing error messages.
 */
interface ITextFieldViewModel {
  /** Value of the text field. */
  value: InputValue;

  /** Indicates whether the text field is in error state. */
  isValid: boolean;

  /** Node rendering the error message(s). */
  errorMessage: ReactNode;

  /**
   *  When true, an eye icon will appear on the right side of the input,
   * and can be toggled to show or not the password.
   */
  showPassword: boolean;

  /**
   * Has the form field been manipulated. Checks the validateables map on the formModel
   * for the given input.
   */
  touched: boolean;

  /**
   * Sets the touched value for the given property.
   */
  setTouched: () => void;
}

/**
 * Generates a {@link useViewModel view model} **argument** based on props passed to the `TextField`.
 * Useful because: The `TextField` component can either be used normally,
 * by passing the value, error messages, etc, or
 * with the help of {@link FormModel} which handles validation
 * error messages, etc. This helper makes sure that the
 * component itself doesn't worry about where the value, error flag,
 * and the error messages are coming from, be it from the props
 * or through the `FormModel` provided in the props.
 *
 * @param props - Props passed to the `TextField`.
 * @returns Object that should be passed to `useViewModel()`.
 */
const useTextFieldViewModel = <T extends IForm>(
  props: ITextFieldProps<T>
): ITextFieldViewModel => {
  const { formModel, fieldName } = props;

  /**
   * Note - type of key is `never` but don't let that confuse you. Here's why:
   * The `FormModel` shouldn't be used directly, it's a general class.
   * And it has no properties (input fields). Therefore, there are no
   * keys that the `FormModel` accepts for validation, or anything, since
   * it has no fields itself. Therefore, DeriveFieldProperties<FormModel>
   * is never, and the key below is also never. This function could be
   * rewritten to hava generic TS argument so it can know the extended
   * form model class and its properties, but we really don't need
   * that information since we're just grabbing the value.
   */
  const [vm] = useViewModel({
    /**
     * Gets a fresh value from the form field.
     *
     * A getter is necessary here, instead of just declaring `value: form[key]`
     * because that would just copy the primitive value from the form and
     * detach it from the form. Therefore, the view model would not reflect the
     * actual value in the form.
     *
     * @returns Value of the form.
     */
    get value(): Exclude<ITextFieldViewModel['value'], undefined> {
      return formModel[fieldName] as Exclude<
        ITextFieldViewModel['value'],
        undefined
      >;
    },

    /**
     * Sets a value for the form field.
     *
     * A setter is necessary because setting the value requires a
     * reference to the form to be used: `form[key]`.
     * See explanation for `getter` above. Same holds here, if there was
     * no setter and the value was held directly by the view model,
     * it would be detached from the form model.
     */
    set value(value: Exclude<ITextFieldViewModel['value'], undefined>) {
      formModel[fieldName] = value as T[typeof fieldName];
    },

    /** @inheritdoc */
    get touched(): boolean {
      return formModel.getTouch(fieldName);
    },

    /** @inheritdoc */
    setTouched: () => {
      formModel.setTouch(fieldName);
    },

    /** @inheritdoc */
    get isValid(): boolean {
      return formModel.validateProperty(fieldName).isValid;
    },

    /** @inheritdoc */
    get errorMessage(): ReactNode {
      const { errors } = formModel.validateProperty(fieldName);
      // When the `key` argument is present for `validate()`
      // there can only be 1 invalid property in the array.
      // If the array is empty, there are no errors.
      const displayError = errors[0] ?? '';

      // Render a fragment containing all the errors.
      return displayError ? <span>{displayError}</span> : undefined;
    },
    showPassword: false
  });

  return vm;
};

/**
 * **READ BEFORE USING**.
 *
 * The `TextField` component is a sophisticated input wrapper.
 *
 * With the help of `mobx` and our first-party
 * {@link FormModel}. In this version, the parent component must be an
 * observer component. The parent creates a form model, and passes
 * the form model to the `TextField` component. Entire state is managed
 * by the `FormModel`. This method is our go-to for forms in this codebase.
 *
 *
 * ### Using the `FormModel`.
 * @example
 * ```tsx
 * interface ITestForm {
 *  firstName: string;
 *  lastName: string;
 * }
 *
 * // Observable form model (via mobx).
 * class TestFormModel extends FormModel<ITestForm> {
 *   .@observable
 *   .@validatable({
 *     name: 'form.firstName',
 *     validations: [minLength(2), maxLength(128)]
 *   })
 *   public firstName: string;
 *
 *   .@observable
 *   .@validatable({
 *     name: 'form.lastName',
 *     validations: [minLength(2), maxLength(128)]
 *   })
 *   public lastName: string;
 *
 *   public constructor(dto: DTO<ITestForm>) {
 *     super(dto);
 *     this.firstName = dto.firstName;
 *     this.lastName = dto.lastName;
 *     // IMPORTANT to call this!
 *     makeObservable(this);
 *   }
 *
 *   public toDTO(): DTO<ITestForm> {
 *     return {
 *       firstName: this.firstName,
 *       lastName: this.lastName
 *     }
 *   }
 * }
 *
 * const Form: ReactComponent = observer(() => {
 *   // IMPORTANT to use useState! Otherwise the model gets replaced on
 *   // every render and state will reset every time.
 *   const [form] = useState(
 *     new TestFormModel({
 *       firstName: '',
 *       lastName: ''
 *     })
 *   );
 *   return (
 *     <form>
 *       <TextField label="* First Name" formModel={form} fieldName="firstName" />
 *       <TextField label="* Last Name" formModel={form} fieldName="lastName" />
 *       <button
 *         disabled={!form.isValid}
 *         submit
 *       >
 *           Submit
 *         </button>
 *     </form>
 *   )
 * });
 * ```
 */
export const TextField = observer(function TextField<T extends IForm>(
  props: ITextFieldProps<T>
) {
  const {
    id: propId,
    label,
    fullWidth,
    error = false,
    helperText,
    tooltip,
    tooltipArguments,
    onBlur,
    showablePassword,
    type,
    fieldName,
    formModel,
    isOptional,
    isInInputMask,
    className,
    datatestID,
    enterInteractionDetails,
    ...rest
  } = props;

  const reactId = useId();
  const vm = useTextFieldViewModel<T>(props);
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = useCallback(() => setShowPassword((p) => !p), []);

  const fieldType = type === 'password' && showPassword ? 'text' : type;
  const id = propId ?? reactId;

  const inputRef = useRef<HTMLInputElement>(null);

  // Handles the focusin event on text field. It triggers only once and will send
  // whatever 'userInteraction' event is assigned to the field.
  useEffect(() => {
    let fieldEntered = false;
    const textClick = (): void => {
      if (!fieldEntered && enterInteractionDetails) {
        UserInteractionService.makeAction(enterInteractionDetails);

        fieldEntered = true;
      }
    };

    if (inputRef.current) {
      inputRef.current.addEventListener('focusin', textClick);
    }

    return (): void =>
      inputRef.current?.removeEventListener('focusin', textClick);
  }, []);

  return (
    <div
      className={classes(S.container, className, {
        [S.fullWidth]: fullWidth,
        [S.error]: !vm.isValid || error
      })}
    >
      <div className={S.labelWrapper} data-item="aaaaabbbb">
        <label htmlFor={id}>
          {!isOptional ? msg(forms_requiredIndicator) : ''}
          {label}
        </label>
        {tooltip && (
          <Tooltip body={tooltip} className={S.icon} {...tooltipArguments}>
            <Icon
              title={tooltipArguments?.title}
              ariaLabel={tooltipArguments?.title}
              className={S.iconTooltip}
              icon={IconTypes.QuestionCircle}
            />
          </Tooltip>
        )}
      </div>
      <div className={S.inputWrapper}>
        <input
          id={id}
          aria-describedby=""
          name={fieldName}
          className={S.input}
          data-testid={datatestID}
          onInput={(e) => {
            vm.setTouched();
            vm.value = e.currentTarget.value;
          }}
          onBlur={(e) => {
            vm.setTouched();
            if (onBlur instanceof Function) {
              onBlur(e);
            }
          }}
          value={isInInputMask ? undefined : vm.value}
          type={fieldType}
          ref={inputRef}
          {...rest}
        />
        {showablePassword && (
          <div className={classes(S.icon, S.iconEye)}>
            <Icon
              icon={showPassword ? IconTypes.EyeSlash : IconTypes.Eye}
              onClick={toggleShowPassword}
            />
          </div>
        )}
      </div>
      {vm.errorMessage ?? (error ? <span>{helperText}</span> : '')}
    </div>
  );
});
